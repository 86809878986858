import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { appInitialized } from "../redux/globalSlice.tsx";
import { orderSucceeded } from "../redux/shoppingCartSlice.tsx";
import OrderArticle from "../models/order/OrderArticle.ts";
import { kioskResetted } from "../redux/kioskSlice.tsx";
import _ from "lodash";
import { JamezzPiggyReward } from "../redux/api/piggyRewardsApi.ts";

export type VoucherDefV2 = (
  | {
      id?: number;
      discountType: "AMOUNT_OFF_TOTAL";
      priceDiscountAmount: number;
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "PERCENTAGE_OFF_TOTAL";
      priceDiscountPercentage: number;
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "AMOUNT_OFF_PRODUCT";
      priceDiscountAmount: number;
      freeProducts_JSON: string[];
      includedProducts_JSON: string[];
      included_products: { apiId1: string | null; apiId2: string | null; id: number; naam: string }[];
      maxChooseItems: number;
      max_discounted_amount: number;
      applied_on_product_ids_through_menus: string[];
      supergroups: { supergroups: { id: string }[] };
    }
  | {
      id?: number;
      discountType: "PERCENTAGE_OFF_PRODUCT";
      priceDiscountPercentage: number;
      freeProducts_JSON: string[];
      includedProducts_JSON: string[];
      included_products: { apiId1: string | null; apiId2: string | null; id: number; naam: string }[];
      maxChooseItems: number;
    }
  | {
      id?: number;
      discountType: "ADD_DISCOUNTED_PRODUCT";
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "COLLECTION";
    }
) & { active: 0 | 1; blocked: 0 | 1; title: string; minBasketPrice?: number };

export type VoucherV2 = {
  voucher: {
    code?: string;
    piggyReward?: Pick<JamezzPiggyReward, "id" | "rewardable_type" | "rewardable_id" | "piggy_uuid">;
    number_of_times: number;
    total_redemptions_allowed?: number;
    times_redeemed?: number;
  };
  voucherdef: VoucherDefV2;
  orderArticles?: OrderArticle[];
  subVouchers?: VoucherV2[];
};

interface VoucherV2State {
  vouchers: VoucherV2[];
  addDiscountedProductVouchers: VoucherV2[];
}

const initState: VoucherV2State = {
  vouchers: [],
  addDiscountedProductVouchers: [],
};

function resetVouchersV2(state: Draft<VoucherV2State>) {
  state.vouchers = [];
  state.addDiscountedProductVouchers = [];
  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function vouchersAdded(vouchers: VoucherV2[], state: Draft<VoucherV2State>) {
  vouchers.forEach((voucherV2) => {
    const voucherIndex = state.vouchers.findIndex((voucher) => voucher.voucher.code === voucherV2.voucher.code);
    const voucher = _.cloneDeep(voucherV2);
    if (voucherIndex >= 0) {
      voucher.voucher.number_of_times += state.vouchers[voucherIndex].voucher.number_of_times;
      state.vouchers.splice(voucherIndex, 1, voucher);
    } else {
      state.vouchers.push(voucherV2);
    }

    if (voucherV2.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT") {
      state.addDiscountedProductVouchers.push(voucherV2);
    }
    voucherV2.subVouchers?.forEach((subVoucher) => {
      if (subVoucher.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT") {
        state.addDiscountedProductVouchers.push(subVoucher);
      }
    });
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function vouchersRemoved(vouchers: VoucherV2[], state: Draft<VoucherV2State>) {
  vouchers.forEach((voucherV2) => {
    const indexToBeRemoved = state.vouchers.findIndex((voucher) => voucher.voucher.code == voucherV2.voucher.code);
    state.vouchers.splice(indexToBeRemoved, 1);
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function numberOfTimesChanged(
  voucherV2s: { voucherCode: string; numberOfTimes: number }[],
  state: Draft<VoucherV2State>
) {
  voucherV2s.forEach((voucherV2) => {
    const index = state.vouchers.findIndex((voucher) => voucher.voucher.code == voucherV2.voucherCode);
    state.vouchers[index].voucher.number_of_times = voucherV2.numberOfTimes;
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function numberOfTimesSum(voucherV2s: { voucherCode: string; numberOfTimes: number }[], state: Draft<VoucherV2State>) {
  voucherV2s.forEach((voucherV2) => {
    const index = state.vouchers.findIndex((voucher) => voucher.voucher.code == voucherV2.voucherCode);
    state.vouchers[index].voucher.number_of_times += voucherV2.numberOfTimes;
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

export const vouchersV2Slice = createSlice({
  name: "vouchersV2",
  initialState: initState,
  reducers: {
    voucherV2Added: (state, action: PayloadAction<VoucherV2>) => {
      vouchersAdded([action.payload], state);
    },
    vouchersV2Added: (state, action: PayloadAction<VoucherV2[]>) => {
      vouchersAdded(action.payload, state);
    },
    vouchersV2NumberOfTimesSum: (state, action: PayloadAction<{ voucherCode: string; numberOfTimes: number }[]>) => {
      numberOfTimesSum(action.payload, state);
    },
    vouchersV2NumberOfTimesChanged: (
      state,
      action: PayloadAction<{ voucherCode: string; numberOfTimes: number }[]>
    ) => {
      numberOfTimesChanged(action.payload, state);
    },
    voucherV2NumberOfTimesChanged: (state, action: PayloadAction<{ voucherCode: string; numberOfTimes: number }>) => {
      numberOfTimesChanged([action.payload], state);
    },
    voucherV2Removed: (state, action: PayloadAction<VoucherV2>) => {
      vouchersRemoved([action.payload], state);
    },
    vouchersV2Removed: (state, action: PayloadAction<VoucherV2[]>) => {
      vouchersRemoved(action.payload, state);
    },
    voucherOrderArticleConfirmed: (state, action: PayloadAction<OrderArticle>) => {
      function findAddDiscountedVoucher(vouchers: VoucherV2[]): VoucherV2 | null {
        for (const voucher of vouchers) {
          if (
            voucher.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT" &&
            (!voucher.orderArticles || voucher.orderArticles.length === 0)
          ) {
            return voucher;
          } else if (voucher.subVouchers) {
            const found = findAddDiscountedVoucher(voucher.subVouchers);
            if (found) {
              return found;
            }
          }
        }
        return null;
      }

      const voucher = findAddDiscountedVoucher(state.vouchers);
      if (voucher) {
        if (!voucher.orderArticles) {
          voucher.orderArticles = [];
        }
        voucher.orderArticles.push(action.payload);
        state.addDiscountedProductVouchers.splice(0, 1);
      }
      localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
    },
    vouchersDeclined: (state) => {
      resetVouchersV2(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appInitialized, (state) => {
        const vouchersCartJson = localStorage.getItem("V5.vouchersV2.vouchers");
        let vouchersCart: VoucherV2[] = [];
        if (vouchersCartJson != null) {
          vouchersCart = JSON.parse(vouchersCartJson) as VoucherV2[];
        }

        state.vouchers = vouchersCart.map((voucher) => {
          //// Make state backwards compatible
          if (voucher.voucher.number_of_times == null) {
            voucher.voucher.number_of_times = 1;
          }
          return voucher;
        });
      })
      .addCase(orderSucceeded, (state) => {
        resetVouchersV2(state);
      })
      .addCase(kioskResetted, (state) => {
        resetVouchersV2(state);
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  voucherV2Added,
  vouchersV2Added,
  voucherV2Removed,
  vouchersV2Removed,
  voucherOrderArticleConfirmed,
  vouchersDeclined,
  voucherV2NumberOfTimesChanged,
  vouchersV2NumberOfTimesChanged,
  vouchersV2NumberOfTimesSum,
} = vouchersV2Slice.actions;

export default vouchersV2Slice.reducer;
