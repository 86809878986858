import { rtkQueryApi } from "./rtkQueryApi";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types.ts";
import OrderArticle from "../../models/order/OrderArticle.ts";

const simphonyEndpoints = rtkQueryApi.enhanceEndpoints({});

export type CheckCalculator = {
  discountCardNr: string;
  items: OrderArticle[];
  voucherOrderItems: OrderArticle[];
  oracleDiscountIds?: number[];
};

export type ItemDiscount = {
  id?: number;
  discountId: number;
  name: string;
  total: number;
};

export type CheckCalculatorResponse = {
  data: {
    accept: boolean;
    discounts: ItemDiscount[];
    response?: {
      discounts: ItemDiscount[];
      menuItems?: {
        itemDiscounts?: ItemDiscount[];
        condiments: { condimentId: number; itemDiscounts?: ItemDiscount[] }[];
      }[];
    };
  };
} & JamezzApiResponse;

export const simphonyApi = simphonyEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    checkCalculator: builder.mutation<CheckCalculatorResponse, CheckCalculator>({
      query: (params) => {
        return {
          url: `/v5_2/kiosk/checkcalculator`,
          method: "POST",
          body: params,
        };
      },
    }),
  }),
});

export const {} = simphonyApi;
