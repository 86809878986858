import { useCallback, useEffect } from "react";
import { httpPost } from "./api/http";
import { setSessionState } from "./redux/globalSlice";
import JamezzApi from "./api/JamezzApi";
import store, { useAppSelector } from "./redux/store";
import useSalesareaData from "./api/useSalesareaData";
import { createReturnPath } from "./config";
import { customFieldsResponseRetrieved } from "./redux/customFieldsSlice.ts";

export const enum ApplicationType {
  QR = "QR",
  KIOSK = "KIOSK",
}

let requestPending = false;
const refreshTimeout = import.meta.env.MODE !== "development" ? 120000 : 120000;

export default function useSessionState() {
  const { getSalesareaData } = useSalesareaData();

  const sessionOutOfSync = useAppSelector((state) => state.global.sessionOutOfSync);

  const getSessionState = useCallback(() => {
    if (requestPending || !store.getState().global.hasInternetConnection) {
      if (requestPending) {
        console.log("requestPending");
      }
      if (!store.getState().global.hasInternetConnection) {
        console.log("no internet connection");
      }
      return;
    }
    requestPending = true;
    const searchParams = new URLSearchParams(document.location.search);
    const orderId = searchParams.get("oid") ?? "";
    const hash = searchParams.get("hash") ?? "";
    const cardnr = searchParams.get("cardnr") ?? "";

    httpPost(
      JamezzApi.get.getSessionState.url,
      JamezzApi.post.loginSession.data(
        store.getState().global.webcode,
        window.location.origin + createReturnPath(),
        orderId,
        hash,
        cardnr
      ),
      {
        showSpinner: false,
        showToastError: false,
        showToastSuccess: false,
      }
    )
      .then((response: any) => {
        if (response.data.data.activeState === -1) {
          return;
        }

        const appStyling = {
          "JS-QrTheme": {},
          "JS-QrGlobal": {},
        };
        let customData = response.data.data.custom_data;
        if (customData) {
          customData = JSON.parse(customData);
        }
        if (customData?.["JS-QrTheme"]) {
          appStyling["JS-QrTheme"] = customData?.["JS-QrTheme"];
        }
        if (customData?.["JS-QrGlobal"]) {
          appStyling["JS-QrGlobal"] = customData?.["JS-QrGlobal"];
        }

        if (customData?.customFonts && Array.isArray(customData?.customFonts)) {
          const head = document.getElementsByTagName("head")[0];

          customData?.customFonts.forEach((customFont: string) => {
            if (!head.innerHTML.includes('<link rel="stylesheet" href="' + customFont + '">')) {
              const link = document.createElement("link");
              link.href = customFont;
              link.rel = "stylesheet";

              head.appendChild(link);
            }
          });
        }

        if (JSON.stringify(response.data.data) !== JSON.stringify(store.getState().global.sessionState)) {
          store.dispatch(setSessionState(response.data.data));
        }

        store.dispatch(customFieldsResponseRetrieved(response.data.data.customFields));
        getSalesareaData();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        requestPending = false;
      });
  }, [getSalesareaData]);

  useEffect(() => {
    getSessionState();
  }, [getSessionState, sessionOutOfSync]);

  useEffect(() => {
    const timeout = window.setInterval(() => {
      getSessionState();
    }, refreshTimeout);

    return () => {
      if (timeout) {
        window.clearInterval(timeout);
      }
    };
  }, [getSessionState]);
}
