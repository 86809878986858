import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { piggyRewardsApi } from "../api/piggyRewardsApi.ts";
import _ from "lodash";

export const selectPiggyRewardsToBeClaimed = createSelector(
  [
    (state: RootState) => state.vouchersV2.vouchers,
    (state) => piggyRewardsApi.endpoints.fetchContactRewards.select()(state).data?.data,
  ],
  (vouchers, rewards) => {
    const rewardsByKeyPiggyUuid = _.keyBy(rewards, "uuid");

    return vouchers
      .filter((voucher) => voucher.voucher.piggyReward != null)
      .map((voucher) => {
        const piggyReward = voucher.voucher.piggyReward?.piggy_uuid
          ? rewardsByKeyPiggyUuid[voucher.voucher.piggyReward.piggy_uuid]
          : null;
        return { voucher, piggyReward: piggyReward };
      });
  }
);

export const selectNumberOfCreditsOfPiggyRewardsToBeClaimed = createSelector(
  [selectPiggyRewardsToBeClaimed],
  (piggyRewards) => {
    return piggyRewards.reduce((total, item) => {
      if (item.piggyReward?.required_credits) {
        total += item.piggyReward.required_credits * item.voucher.voucher.number_of_times;
      }
      return total;
    }, 0);
  }
);
