import { rtkQueryApi } from "./rtkQueryApi";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types.ts";
import { transformVoucherV2 } from "../../vouchersV2/api/vouchersApi.tsx";
import { VoucherV2Response } from "../../vouchersV2/api/VoucherResponses.ts";

const piggyRewardsEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["piggy-rewards-v2"],
});
export type JamezzPiggyReward = {
  id: number;
  rewardable_type: string;
  rewardable_id: number;
  rewardable: unknown;
  piggy_uuid: string;
};

export type PiggyRewardV2 = {
  id: number;
  media: {
    type: "image";
    value: string;
  } | null;
  title: string;
  description: string | null;
  reward_type: "PHYSICAL";
  required_credits: number;
  uuid: string;
  jamezzPiggyReward: JamezzPiggyReward;
};
export type RewardsResponse = {
  data: PiggyRewardV2[];
} & JamezzApiResponse;

export const piggyRewardsApi = piggyRewardsEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchContactRewards: builder.query<RewardsResponse, void>({
      query: () => {
        return {
          url: `/v5_2/piggy/rewards/contact-rewards`,
          method: "GET",
        };
      },
      // can't get individual items anyway,
      // so even though it's a list it doesn't really matter ever.
      // maybe later if it ever makes sense
      providesTags: [{ type: "piggy-rewards-v2", id: "LIST" }],
      transformResponse(baseQueryReturnValue: RewardsResponse) {
        return {
          ...baseQueryReturnValue,
          data: baseQueryReturnValue.data.map((piggyReward) => {
            if (piggyReward.jamezzPiggyReward.rewardable_type == "App\\voucherdef") {
              piggyReward.jamezzPiggyReward.rewardable = transformVoucherV2({
                voucher: {},
                voucherdef: piggyReward.jamezzPiggyReward.rewardable,
              } as VoucherV2Response).voucherdef;
            }
            return piggyReward;
          }),
        };
      },
    }),
  }),
});

export const { useFetchContactRewardsQuery } = piggyRewardsApi;
